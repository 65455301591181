.productos-destacados {
    background-color: #f8f8f8;
    /* Cambia el color de fondo según tu preferencia */
    padding: 40px 0;
}

.container {
    text-align: center;
    color: #333;
    /* Cambia el color de texto según tu preferencia */
}

h2 {
    font-size: 2.5rem;
    /* Ajusta el tamaño de fuente según tus necesidades */
    margin-bottom: 20px;
}

.tarjetas {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}

.tarjeta {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 calc(33.33% - 20px);
    background-color: #fff;
    /* Cambia el color de fondo de las tarjetas */
    border: 1px solid #ddd;
    /* Cambia el borde de las tarjetas según tu preferencia */
    text-align: center;
    height: 300px;
    margin-bottom: 10%;
    width: auto !important;
    -webkit-box-shadow: 0px 10px 54px -19px rgba(0, 0, 0, 0.36);
    -moz-box-shadow: 0px 10px 54px -19px rgba(0, 0, 0, 0.36);
    box-shadow: 0px 10px 54px -19px rgba(0, 0, 0, 0.36);
    position: relative;
}


h3 {
    font-size: 1.5rem;
    /* Ajusta el tamaño de fuente según tus necesidades */
    margin-top: 10px;
}

/* Estilos para el botón (puedes personalizarlos según tu diseño) */
.btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    margin-top: 20px;
    cursor: pointer;
}

.btn-primary:hover {
    background-color: #0056b3;
}


.btn-outline-white {
    border: 2px solid #000;
    background-color: #fff;
    color: #000;
    border-radius: 10px;
    font-size: 24px;

    -webkit-box-shadow: 0px 10px 54px -33px rgba(0, 0, 0, 0.36);
    -moz-box-shadow: 0px 10px 54px -33px rgba(0, 0, 0, 0.36);
    box-shadow: 0px 10px 54px -33px rgba(0, 0, 0, 0.36);
}


.btn-outline-white:hover {
    border: 2px solid #000;
    background-color: #fefeef;

    -webkit-box-shadow: 0px 10px 92px -32px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 10px 92px -32px rgba(0, 0, 0, 0.57);
    box-shadow: 0px 10px 92px -32px rgba(0, 0, 0, 0.57);
}

.h-card-producto{
    height: 100px !important;
}