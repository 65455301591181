.action-card {
    border-radius: 10px;
    background-color: #fff;
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.action-card:hover {
    transform: translateY(-5px);
    /* Eleva la tarjeta 5px hacia arriba al pasar el cursor */
    -webkit-box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.38);
    /* Agrega una sombra sutile al pasar el cursor */
}