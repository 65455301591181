.hero-container {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2FBG-HERO-OVERLAY.png?alt=media&token=49a03ae7-0bee-43cd-9cc2-7a06c9d3eb90');
    background-size: cover;
    background-position: center;
    height: 100vh;
    /* Ajusta la altura según tus necesidades */
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-container-general {
    background-size: cover;
    background-position: center;
    height: 70vh;
    /* Ajusta la altura según tus necesidades */
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-content {
    text-align: center;
    color: #fff;
    /* Cambia el color de texto según tu preferencia */
}

h1 {
    font-size: 3rem;
    /* Ajusta el tamaño de fuente según tus necesidades */
    margin-bottom: 20px;
}


img {
    max-width: 25%;
    /* Ajusta el ancho máximo de la imagen según tus necesidades */
}

@media only screen and (max-width: 600px) {
    .logo-hero{
        max-width: 60%;
    }
}