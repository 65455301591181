.por-que-elegirnos {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2FBg-porque-elegirnos.png?alt=media&token=c7c16548-517f-4573-9231-7b928de36848");
    background-size: cover;
    background-position: center;
    padding: 40px 0;
    min-height: 100vh;
}

@media only screen and (max-width: 600px) {
    .por-que-elegirnos {
        min-height: 117vh;
    }

    #description{
        font-size: 22px !important;
        text-align: center;
    }
}

.container {
    text-align: center;
    color: #333;
    /* Cambia el color de texto según tu preferencia */
}

h2 {
    font-size: 2.5rem;
    /* Ajusta el tamaño de fuente según tus necesidades */
    margin-bottom: 20px;
}

p {
    font-size: 1.5rem;
    /* Ajusta el tamaño de fuente según tus necesidades */
    margin-bottom: 20px;
}

/* Estilos para el botón (puedes personalizarlos según tu diseño) */
.btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
}

.btn-primary:hover {
    background-color: #0056b3;
}