.testimonios {
    min-height: 80vh;
    padding: 5%;
}

.testimonio {
    border-radius: 10px;
    padding: 4%;
    -webkit-box-shadow: 0px 10px 83px -27px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0px 10px 83px -27px rgba(0, 0, 0, 0.23);
    box-shadow: 0px 10px 83px -27px rgba(0, 0, 0, 0.23);
}

.text-muted {
    color: #716D6D;
}

.icon-user {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Fshield-user-svgrepo-com%201.png?alt=media&token=0331ca13-3806-410b-a8a7-ae94b43b8c11");
    width: 110px;
    height: 110px;
    background-size: contain;
    background-position: center;
    /* background-color: linear-gradient(
        325deg,
        rgb(0, 0, 0) 0%,
        rgba(110, 110, 110, 0.164) 100%
        ); */
    background-repeat: no-repeat;
}

.icon-footer{
    width: 40px;
    height: 40px;
    margin-right: 3%;
}