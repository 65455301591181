@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
    font-family: 'Poppins';
}

body {
    background: #F2F2FA;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

footer {
    background-color: #000;
}

a {
    text-decoration: none;
    color: inherit;
}

/*Native Components */

.bg-dark {
    background-color: #000 !important;
}

.active {
    background: rgb(149, 22, 22) !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: rgb(224, 222, 222);
    border-radius: 0;
}

.nav-link {
    color: #fff;

}

.nav-link:hover {
    color: #b8b8b8;
}


/* Hero banners */
.bannerHero {
    width: 50%;
    padding: 1em;
}



/* Texts */

.heroTitle {
    font-size: 50px;
    color: #fff;
    text-align: left;
}


.text-dark {
    color: #000 !important;
}

/* Buttons */
.btn-more-info {

    width: auto;
    align-self: center;
    background-color: #000;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 20px;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.btn-more-info:hover {
    color: #fff;
    background-color: #000;
}

/* Images */
.imageHeroBanner {
    height: 500px;
}

.logo_container_nav_color {
    width: 150px;
    height: 120px;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2FLOGO-COIL-INSTRUMENTACION.png?alt=media&token=8ee0c3e9-67f1-46e8-8d77-cfc0e1acbf75")
}

.logo_container_footer_color {
    width: 200px;
    height: 200px;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2FLOGO-COIL-INSTRUMENTACION.png?alt=media&token=8ee0c3e9-67f1-46e8-8d77-cfc0e1acbf75")
}

.logo_container_nav_color_cms {
    width: 70px;
    height: 70px;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2FLOGO-COIL-INSTRUMENTACION.png?alt=media&token=8ee0c3e9-67f1-46e8-8d77-cfc0e1acbf75")
}

.logo_container_nav_white {
    width: 300px;
    height: 120px;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2FLOGO-COIL-INSTRUMENTACION.png?alt=media&token=8ee0c3e9-67f1-46e8-8d77-cfc0e1acbf75")
}



/* Hero */

#heroPage {
    background-size: cover;
    width: 100%;
    background-position: top;
    display: flex !important;
    align-items: center !important;
    padding: 2%;
    min-height: 600px;
}


/* Financiamiento */
.container-equipo-plan-financiamiento {
    background: url("https://firebasestorage.googleapis.com/v0/b/prosesamed-com.appspot.com/o/equipo-home-prosesamed.webp?alt=media&token=04cdc46d-8a69-4e96-9074-556aee39e9dd");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 600px;
}

.bg-light-gray {
    background-color: #F9F9F9;
}

/* Fin financiamiento */

/* Servicios de interes */
.container-circle-bg {
    background: url("https://firebasestorage.googleapis.com/v0/b/prosesamed-com.appspot.com/o/photo-1558591710-4b4a1ae0f04d.webp?alt=media&token=6f850de3-9386-40f1-8cfc-13851e48780c");
    background-position: center;
    height: 600px;
    width: 600px;
    background-size: cover;
    border-radius: 50%;
}

/* Fin servicios de interes */

/* Mas razones comprar */
.reason-card {
    background: #000;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 550px;
    padding: 1em;
    display: flex;
}

.icon-secure {
    background: url("https://firebasestorage.googleapis.com/v0/b/prosesamed-com.appspot.com/o/razones-comprar-footer%2Fimagenes-_Mesa-de-trabajo-1.webp?alt=media&token=1db95426-958a-4d0b-a7ac-0e7ecccd6b57");
    background-position: center;
    height: 140px;
    width: 140px;
    background-size: cover;
    background-repeat: no-repeat;
}

.icon-garantia {
    background: url("https://firebasestorage.googleapis.com/v0/b/prosesamed-com.appspot.com/o/razones-comprar-footer%2Fimagenes-04.webp?alt=media&token=29e8bdb3-166e-4435-ac44-3dfaac243afc");
    background-position: center;
    height: 140px;
    width: 140px;
    background-size: cover;
    background-repeat: no-repeat;
}

.icon-servicio-premium {
    background: url("https://firebasestorage.googleapis.com/v0/b/prosesamed-com.appspot.com/o/razones-comprar-footer%2Fimagenes-02.webp?alt=media&token=f77db31c-c55d-424b-83e9-8208a742434d");
    background-position: center;
    height: 140px;
    width: 140px;
    background-size: cover;
    background-repeat: no-repeat;
}

.icon-financiamiento {
    background: url("https://firebasestorage.googleapis.com/v0/b/prosesamed-com.appspot.com/o/razones-comprar-footer%2Fimagenes-03.webp?alt=media&token=9ea83931-25c9-4937-80ae-f713da00d0e6");
    background-position: center;
    height: 140px;
    width: 140px;
    background-size: cover;
    background-repeat: no-repeat;
}

/* Fin mas razones comprar */

/* Footer */

.suscribe-card {
    background: linear-gradient(255.15deg, #4F4F4F 2.81%, #383838 98.65%);
    border-radius: 20px;
}


/* Fin footer */


/* **** Equipos **** */
.card-equipo {
    height: 330px !important;
}

.equipo-container-card {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 50%;
    width: 100%;
}

.equipo-card-image {
    object-fit: cover;
    width: 300px;
    height: 14em;
    align-self: center;
}

/* **** Fin Equipos **** */

/* ****Equipo visualizer ***** */
.image-visualizer {
    width: 100%;
    height: 50%;
}

.preview-equipo {
    height: 100px;
    width: 100px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    opacity: 0.75;
}

.preview-equipo-cotizar {
    height: 100px;
    width: 100px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
}

.preview-equipo-modal {
    width: 300px;
    height: 300px;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
}

.preview-equipo:hover {
    opacity: 1;
    cursor: pointer;
    transition-delay: 0s, 0s;
}

.caracteristica-name {
    height: 50px;
    background-color: rgb(235, 235, 235);
    border-bottom: 0.5px solid #d7d7d7;
    display: flex;
    align-items: center;
}

.caracteristica-description {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 0.5px solid #d7d7d7;
    background-color: rgba(243, 243, 243, 0.781);
}

/* **** Fin Equipo visualizer ***** */

/* *** Gracias page *** */

.check-confirmation {
    background: url("https://img.freepik.com/foto-gratis/hombre-negocios-primer-plano-comprobando-portapapeles_23-2148479569.jpg?w=740&t=st=1668026798~exp=1668027398~hmac=1fac95ec9f1a2bfb175637b61725de03634d35a4be040439920b60d0f2265c79");
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    height: 300px;
    width: 300px;
}

/* *** Fin Gracias page *** */

/* *** Not found *** */

.not-found-bg {
    background: url("https://img.freepik.com/vector-gratis/interior-vacio-galeria-arte-sala-3d-paredes-blancas-piso-lamparas-iluminacion-pasillos-museo-luces-presentacion-imagenes-sala-exposiciones-concurso-fotografia_107791-4221.jpg?w=900&t=st=1668028387~exp=1668028987~hmac=a56e320eb2e421e07ad7f516b8d35d8a0732346dcce2549d9cbda1fed61f641a");
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    height: 300px;
    width: 300px;
}

/* *** Fin Not found *** */


/* ********* Financiamiento ********* */



.icon-financiamiento-small {
    background: url("https://firebasestorage.googleapis.com/v0/b/prosesamed-com.appspot.com/o/icons%2FFinanciamiento-icon.svg?alt=media&token=868a5dc8-c1af-4b63-aafd-e8bb66b5a5e8");
    background-position: center;
    height: 50px;
    width: 50px;
    border-radius: 2px;
    background-size: contain;
    background-repeat: no-repeat;
}

.icon-form-small {
    background: url("https://firebasestorage.googleapis.com/v0/b/prosesamed-com.appspot.com/o/icons%2FChecklist-icon.svg?alt=media&token=ea3a49f7-c32a-4f3f-a9cc-ce627edac67d");
    background-position: center;
    height: 50px;
    width: 50px;
    border-radius: 2px;
    background-size: contain;
    background-repeat: no-repeat;
}

.icon-money-small {
    background: url("https://firebasestorage.googleapis.com/v0/b/prosesamed-com.appspot.com/o/icons%2FMoney-icon.svg?alt=media&token=a340c7c2-f945-43eb-941a-0e3cddfe576a");
    background-position: center;
    height: 50px;
    width: 50px;
    border-radius: 2px;
    background-size: contain;
    background-repeat: no-repeat;
}

.icon-calendar-small {
    background: url("https://firebasestorage.googleapis.com/v0/b/prosesamed-com.appspot.com/o/icons%2FCalendar-icon.svg?alt=media&token=7e9bb75d-84f0-490f-8560-6089efa197c7");
    background-position: center;
    height: 50px;
    width: 50px;
    border-radius: 2px;
    background-size: contain;
    background-repeat: no-repeat;
}

.icon-check-small {
    background: url("https://firebasestorage.googleapis.com/v0/b/prosesamed-com.appspot.com/o/icons%2FCheck-circle-icon.svg?alt=media&token=3a8f4cb7-46e7-4c02-be58-c42464e25d30");
    background-position: center;
    height: 50px;
    width: 50px;
    border-radius: 2px;
    background-size: contain;
    background-repeat: no-repeat;
}

/* ********* Ends Financiamiento ********* */



/* *** Backoffice */

.container-cotizaciones {
    border-radius: 10px;
    min-height: 300px;
}

.preview-image-project {
    width: auto;
    height: 100px;
}

/* *** Ends Backoffice */

/* ***** Servicios ***** */
.service-img {
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    width: 300px;
    height: 300px;
}

/* ***** Fin Servicios ***** */

/* *** LOGIN *** */

.iconContainerSmall {
    background: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2FLOGO-COIL-INSTRUMENTACION.png?alt=media&token=8ee0c3e9-67f1-46e8-8d77-cfc0e1acbf75");
    width: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 150px;
}

/* *** FIN LOGIN *** */

/* *** Contacto **** */
.map {
    width: 600px;
    height: 450px;
}

/* **** Fin Contacto **** */


/* Wabutton */
.wp-button {
    margin-top: 2px;
}

.float {
    position: fixed;
    width: 200px;
    height: 50px;
    bottom: 40px;
    right: 15px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px !important;
    padding: 2% !important;
    text-align: center;
    font-size: 35px;
    z-index: 100;
    display: flex;
    align-items: center;
}

.text-btn {
    font-size: 16px;
    color: #fff;
    margin: 2%;
}

.text-btn:hover {
    color: #25d366 !important;
}

.float:hover {
    background-color: #FFF;
    border: 0px;
    color: #25d366 !important;
    border: 1px solid #25d366;
    transition: 0.3s;
}


.btn-green {
    background: #4a4a4a;
}

.btn-green:hover {
    background: #4fbba6;
}

/* Landing */

.bg-blue-marin {
    background-color: #1F0092;
}

.img-landing {
    height: auto;
    width: 90%;
}

/* Custom */

.fs-14 {
    font-size: 14px !important;
}

.bg-blue-kav {
    background-color: #3374db;
}

.border-light-blue-hover:hover {
    border: 0.5px solid #018fe0;
}

.shadow-hover:hover {
    transition-delay: 100ms;
    animation: fadeIn 300ms;
    -webkit-box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.38);
}

.cs-pointer {
    cursor: pointer;
}

.btn-outline-dark {
    color: #000;
}

.bg-light-blue-icon {
    height: 60px;
    width: 60px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F1F4F8 !important;
}

.bg-light-blue {
    background-color: #F1F4F8 !important;
}

.w-80 {
    width: 80% !important;
}

.minHeight-100 {
    min-height: 100vh;
}

.nav-gray {
    background-color: #ececec;
    height: auto;
    border-radius: 10px;
}

.bg-dark-blue {
    background-color: rgb(10, 12, 28);
}

.fade-div {
    animation: fadeIn 300ms;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fs-10 {
    font-size: 10px;
    line-height: 1.0 !important;
}

.fs-12 {
    font-size: 12px;
    line-height: 1.0 !important;
}

.op-1 {
    opacity: 1;
}

.r-10 {
    border-radius: 10px;
}

.bborder-dark {
    border-bottom: 1px solid #ff0000;
}

.line-right {
    border-right: 1px solid rgb(92, 92, 92);
}

.ph-light::placeholder {
    color: #fff;
}

.btn-dark {
    background-color: #000;
}

.fs-7 {
    font-size: 14px;
    line-height: 1.2em;
}

.bg-light {
    background-color: #FFFFFF !important;
}

.w-70 {
    width: 70%;
}

.w-30 {
    width: 30%;
}

.bred {
    border: 1px solid red
}

.hideScrollbar::-webkit-scrollbar {
    border: none;
}

.hideScrollbar::-webkit-scrollbar-thumb {
    background: rgba(189, 189, 189, 0.27);
    border-radius: 10px;
    border: 0.70px solid rgb(223, 223, 223);
}

.shadow-lite {
    -webkit-box-shadow: 0px 28px 58px -14px rgba(171, 171, 171, 1);
    -moz-box-shadow: 0px 28px 58px -14px rgba(171, 171, 171, 1);
    box-shadow: 0px 28px 58px -14px rgba(171, 171, 171, 1);


}

.tr-shadow-lite:hover {
    -webkit-box-shadow: 0px 28px 58px -14px rgba(171, 171, 171, 1);
    -moz-box-shadow: 0px 28px 58px -14px rgba(171, 171, 171, 1);
    box-shadow: 0px 28px 58px -14px rgba(171, 171, 171, 1);
}

.shadow {
    -webkit-box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.38);
}

.card {
    border: 0;
    height: 25rem;
}

.my-6 {
    margin-top: 6em;
    margin-bottom: 6em;
}


.width-spacer {
    min-width: 300px;
}

.nb-input:focus {
    outline: none !important;
    box-shadow: 0 0 10px #6a6a6a43;
    transition-delay: 0s, 0s;
}

.bg-gray-50 {
    background-color: #f8f8f8;
}

.container-dashboard {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Fforest-simon-1Q6gfwHc5GI-unsplash.jpg?alt=media&token=003d8c8f-d551-4066-8231-49f0f4157dcf");
    background-size: cover;
    background-position: center;
}

.btn-outline {
    background-color: transparent;
    border: 1px solid black;
    padding: 5px;
}

.btn {
    border-radius: 0%;
}

.m-clock {
    border-radius: 50%;
}

.w-20 {
    width: 25%;
}

.xsmall {
    font-size: 12px;
}

.img-services {
    width: 80vh !important;
    border-radius: 4%;
    height: 60vh !important;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Fmech-mind-robotics-sTzb90A1Abs-unsplash.jpg?alt=media&token=e5192c2b-3b97-4127-a962-45a4cba6a9a0");
    background-size: cover;
}

.img-machines {
    width: 80vh !important;
    border-radius: 4%;
    height: 60vh !important;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Fbilly-freeman-nkxB5Ab-ONY-unsplash.jpg?alt=media&token=90835fd3-2be9-4bbe-b73c-4a60f4c64d07");
    background-size: cover;
}

.petroil-photo {
    background: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Farvind-vallabh-rqWWhKzVCaU-unsplash.jpg?alt=media&token=387a42f2-09a9-499d-99c6-c483d85369fa");
    height: 70vh;
    width: 100%;
    background-size: cover;
    background-position: bottom;
}

ul {
    list-style-type: none;
}




.img-card-equipo {
    height: 14em;
    min-width: 14em;
    object-fit: contain;
}

.card-button-wa {
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px !important;
    padding: 2% !important;
    text-align: center;
    font-size: 35px;
    width: auto;
}

.card-button-wa:hover {
    background-color: #25d366;
    color: #FFF;
}

.h-card-producto {
    height: auto !important;
    min-height: 30em;
}

.alert {
    border-radius: 0%;
    border: none;
    background-color: #E3F9E5;
}


.bg-secondary-2 {
    background-color: #737272;
}



.transparent-border-input {
    border: none;
    background-color: rgb(255, 255, 255);

    height: 50px;
    width: 100% !important;
    color: #4f4f4f;
    padding: 1.5em;

    box-shadow: 0px 10px 38px -25px rgba(0, 0, 0, 0.1);
}

.transparent-border-input:focus {
    outline: none !important;
    background-color: rgb(255, 255, 255) !important;
}

.form-select-rounded-transparent {
    border: none;

    height: 50px;
    color: #4f4f4f;
    box-shadow: 0px 10px 38px -25px rgba(0, 0, 0, 0.1);
}

.form-select-rounded-transparent:focus {
    outline: none;
    background-color: rgb(255, 255, 255) !important;
}

.navbar-toggler:focus {
    outline: none !important;
}

.transparent-border-input::placeholder {
    color: rgb(39, 39, 39);
}

.transparent-border-textarea {
    border: none;
    background-color: rgb(255, 255, 255);
    height: 80px;
    width: 100%;
    color: #4f4f4f;
    padding: 1.5em;
    box-shadow: 0px 10px 38px -25px rgba(0, 0, 0, 0.1);

}


.transparent-border-textarea:focus {
    outline: none;
    background-color: rgb(255, 255, 255) !important;
}

.transparent-border-textarea::placeholder {
    color: rgb(39, 39, 39);
}

.book-consulting-img-service {
    width: auto;
    background-size: cover;
    height: 400px;
    margin: 10%;
    align-self: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2Fconfident-call-center-operator-talking-with-client.jpg?alt=media&token=68677a5d-cbbc-491a-8412-9c760efed857");
}

.red-gradient {
    background: rgb(159, 0, 53);
    background: linear-gradient(344deg, rgb(177, 3, 3) 0%, rgb(17, 1, 9) 96%);
}


.btn-solicitar-info {

    background: rgb(159, 0, 53);
    background: linear-gradient(344deg, rgb(177, 3, 3) 0%, rgb(17, 1, 9) 96%);
    min-width: 200px;
    align-self: center;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    transition: all 0.3s ease;
    /* Transición de 0.3 segundos con efecto suave */

}

.btn-solicitar-info:hover {
    color: #fff;
}

.banner-pagos {
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2FBANNER%201%20metodos%20pago.png?alt=media&token=3e375c08-98ec-4ff8-ba89-7f994a392b30');
}

.banner-facturacion {
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/coilinstrumentacion%2FBANNER%201%20facturacion.png?alt=media&token=4353bd3f-d9a0-4ca6-8ea6-26b71007dbce');
}


.testimonial-img {
    width: 10%;
    height: 10%;
    object-fit: cover;
    border-radius: 50%;
}


.mantenimiento {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.shadow-card-mantenimiento {
    border-radius: 20px;
    padding: 5%;


    -webkit-box-shadow: 0px 10px 23px -9px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: 0px 10px 23px -9px rgba(0, 0, 0, 0.48);
    box-shadow: 0px 10px 23px -9px rgba(0, 0, 0, 0.48);
}


.rounded-2xl {
    border-radius: 10px;
}

/* Ends custom */

/* Responsive */
@media only screen and (max-width: 1000px) {


    .testimonial-img {
        width: 30%;
        height: 30%;
    }

    .banner-pagos {
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 120px;
    }

    .map {
        width: 100%;
    }

    .preview-equipo {
        width: 60px;
        height: 60px;
    }

    .equipo-main-image {
        height: 300px !important;
    }

    .preview-equipo-modal {
        height: 200px !important;
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }

    .line-right {
        border-right: none;
        border-bottom: 1px solid rgb(92, 92, 92);
    }

    .hideScrollbar::-webkit-scrollbar {
        display: none;
    }

    #heroPage {
        display: block !important;
        min-height: 450px !important;
    }

    #heroBackgroundGetInTouch {
        display: block !important;
    }

    .service-image {
        margin: auto
    }

    .service-title {
        margin-top: 30px;
    }

    .heroTitle {
        font-size: 35px;
    }

    .title-black {
        font-size: 35px;
    }

    .title-brands {
        font-size: 35px;
    }

    .mockup {
        width: 330px;
    }

    .caption-text-purple {
        font-size: 20px;
    }

    .container-form-description {
        height: auto;
    }

    #pc {
        display: none !important;
    }

    #mb {
        display: block !important;
    }

    .container-description-header {
        width: 100%
    }

    .container-content {
        position: relative;
        top: 10px;
        border-radius: 20px;
        background: #fff;
        padding: 1em;
    }

    .brand-logo {
        width: 90% !important;
        padding: 10px !important;
    }

    .dropdown-menu {
        width: 100%;
    }

    .nav-container {
        height: 120px;
    }

    .line-footer-col {
        border-bottom: 1px solid #fff;
        border-right: none;
        margin-bottom: 1em;
        padding-bottom: 1em;
    }

    .card-testimonial {
        height: auto;
    }


    .container-circle-bg {
        width: 300px;
        height: 300px;
    }


}

#mb {
    display: none;
}